<template>
  <div class="box p_r">
    <img
      class="bg p_r"
      :src="require('@/assets/WholeProcessHealth/bg.png')"
      alt=""
    />
    <div class="pc_ ctx p_a">
      <div class="flex flex_d j_c_c a_i_c" style="">
        <h2 class="title">全程健康管理平台赋能区域医疗管理</h2>
        <div class="tips"></div>
      </div>
      <div class="flex j_c_c a_i_c">
        <div class="item_box" v-for="(item, index) in list" :key="index">
          <div class="item_icon" v-html="item.icon"></div>
          <div class="item_title">
            {{ item.title }}
          </div>
          <div class="item_desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile_">
      <div class="flex flex_d j_c_c a_i_c" style="color: #fff">
        <h2 class="title">全程健康管理平台赋能区域医疗管理</h2>
        <div class="tips"></div>
      </div>
      <div class="block" style="">
        <div class="wp_swiper p_r" style="height: 310px">
          <div class="swiper-wrapper" style="height: 100%">
            <div
              class="swiper-slide"
              v-for="(item, index) in list.length % 2 == 0
                ? list.length / 2
                : parseInt(list.length / 2) + 1"
              style="width: 100vw"
              :key="index"
            >
              <div class="flex">
                <div v-for="(item_, index_) in list" :key="index_">
                  <div
                    class="mobile_item_"
                    v-if="index_ > index * 2 - 1 && index_ < index * 2 + 2"
                  >
                    <div class="mobile_item_nav flex flex_d a_i_s j_c_c">
                      <div
                        v-html="item_.icon"
                        style="margin: 0.1rem 0; width: 0.7rem; height: 0.7rem"
                      ></div>
                      <div style="font-size: 0.16rem; font-weight: 800">
                        {{ item_.title }}
                      </div>
                      <div
                        style="
                          color: #999;
                          font-size: 0.14rem;
                          margin-top: 10px;
                        "
                      >
                        {{ item_.desc }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wp_swiper-pagination swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "区域统筹管理",
          icon: `<svg width="100%" height="100%" viewBox="0 0 58 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M54.6935 47.4464C54.0347 47.4464 53.7053 47.117 53.0464 47.117C52.0582 47.117 51.3994 47.4464 50.4111 47.7758C49.7523 48.1052 48.7641 48.4346 48.1053 49.0935C47.4464 49.7523 46.4582 51.7287 46.4582 51.7287H43.1641C43.1641 51.7287 42.1759 49.4229 41.517 49.0935C40.8582 48.4346 40.1994 48.1052 39.2112 47.7758C38.2229 47.4464 37.5641 47.117 36.5759 47.117C35.917 47.117 35.5876 47.117 34.9288 47.4464L33.2817 44.8111C33.6112 44.4817 33.9406 44.1523 34.27 43.4935C34.9288 42.5052 35.2582 41.1876 35.2582 40.1993C35.2582 38.8817 34.9288 37.8935 34.27 36.9052C33.9406 36.5758 33.6112 36.2464 33.2817 35.5876L34.9288 32.9523C35.5876 32.9523 35.917 33.2817 36.5759 33.2817C37.5641 33.2817 38.2229 32.9523 39.2112 32.6229C39.87 32.2935 40.8582 31.964 41.517 31.3052C42.1759 30.6464 43.1641 28.6699 43.1641 28.6699H46.4582C46.4582 28.6699 47.4464 30.9758 48.1053 31.3052C48.7641 31.964 49.4229 32.2935 50.4111 32.6229C51.3994 32.9523 52.0582 33.2817 53.0464 33.2817C53.7053 33.2817 54.0347 33.2817 54.6935 32.9523L56.3406 35.5876C56.0111 35.917 55.6817 36.2464 55.3523 36.9052C54.6935 37.8935 54.3641 39.2111 54.3641 40.1993C54.3641 41.517 54.6935 42.5052 55.3523 43.4935C55.6817 43.8229 56.0111 44.1523 56.3406 44.8111L54.6935 47.4464Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M47.7764 42.8349C47.1175 43.4938 46.1293 44.1526 44.8116 44.1526C43.8234 44.1526 42.8352 43.8232 41.8469 42.8349C41.1881 42.1761 40.5293 41.1879 40.5293 39.8702C40.5293 38.882 40.8587 37.8938 41.8469 36.9055C42.5058 36.2467 43.494 35.5879 44.8116 35.5879C45.7999 35.5879 46.7881 35.9173 47.7764 36.9055C48.4352 37.5644 49.094 38.5526 49.094 39.8702C49.094 41.1879 48.7646 42.1761 47.7764 42.8349Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M1 24.7168H10.2235" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M5.61182 20.1055V29.329" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.9999 26.6951C30.0915 26.6951 30.9764 25.8102 30.9764 24.7187C30.9764 23.6271 30.0915 22.7422 28.9999 22.7422C27.9083 22.7422 27.0234 23.6271 27.0234 24.7187C27.0234 25.8102 27.9083 26.6951 28.9999 26.6951Z" fill="#21252E"/>
                    <path d="M29 24.7172L40.2 4.62305" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M34.2707 35.9169C32.6236 36.9051 30.6471 37.2345 29.0001 37.2345C22.0824 37.2345 16.4824 31.6345 16.4824 24.7169C16.4824 17.7992 22.0824 12.1992 29.0001 12.1992C35.9177 12.1992 41.5177 17.7992 41.5177 24.7169C41.5177 27.6816 40.5295 30.6463 38.553 32.6228" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M6.9292 16.4817C10.2233 7.58762 18.788 1.6582 28.6704 1.6582C41.5174 1.6582 52.0586 12.1994 52.0586 25.0464C52.0586 27.3523 51.7292 29.6582 51.0704 31.9641" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M34.9292 47.4473C32.9528 48.1062 30.9763 48.1062 28.9998 48.1062C19.7763 48.1062 11.8704 42.8356 7.91748 34.9297" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          desc: "统筹医疗区域医疗资源，提升公卫整体服务效能，夯实分级诊疗体系。",
        },
        {
          title: "医院科学管理",
          icon: `
                    <svg width="100%" height="100%" viewBox="0 0 54 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M51.0227 40.2658C50.4577 40.2658 50.1753 39.9833 49.6103 39.9833C48.7628 39.9833 48.1979 40.2658 47.3504 40.5482C46.7854 40.8307 45.938 41.1132 45.373 41.6782C44.808 42.2432 44.5255 42.8081 44.2431 43.3731C44.2431 43.6556 43.9606 43.6556 43.9606 43.9381H41.1357C41.1357 43.6556 41.1357 43.6556 40.8532 43.3731C40.5707 42.8081 40.0058 41.9607 39.7233 41.6782C39.1583 41.1132 38.5933 40.8307 37.7459 40.5482C36.8984 40.2658 36.3334 39.9833 35.486 39.9833C34.921 39.9833 34.6385 39.9833 34.0736 40.2658L32.6611 38.0059C32.9436 37.7234 33.2261 37.4409 33.5086 36.8759C34.0736 36.0285 34.356 34.8985 34.356 34.0511C34.356 32.9211 34.0736 32.0737 33.5086 31.2262C33.2261 30.9437 32.9436 30.6612 32.6611 30.0963L34.0736 27.8364C34.6385 27.8364 34.921 28.1189 35.486 28.1189C36.3334 28.1189 36.8984 27.8364 37.7459 27.5539C38.3109 27.2714 39.1583 26.9889 39.7233 26.4239C40.1446 26.0026 41.1357 24.8071 41.1357 24.1641H43.9606C43.9606 24.8461 44.9137 25.9647 45.373 26.4239C45.938 26.9889 46.5029 27.2714 47.3504 27.5539C48.1979 27.8364 48.7628 28.1189 49.6103 28.1189C50.1753 28.1189 50.4577 28.1189 51.0227 27.8364L52.4351 30.0963C52.1527 30.3788 51.8702 30.6612 51.5877 31.2262C51.0227 32.0737 50.7402 33.2036 50.7402 34.0511C50.7402 35.181 51.0227 36.0285 51.5877 36.8759C51.8702 37.1584 52.1527 37.4409 52.4351 38.0059L51.0227 40.2658Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M45.0907 36.3104C44.5257 36.8754 43.6782 37.4403 42.5483 37.4403C41.7008 37.4403 40.8534 37.1579 40.0059 36.3104C39.4409 35.7454 38.876 34.898 38.876 33.768C38.876 32.9206 39.1585 32.0731 40.0059 31.2256C40.5709 30.6607 41.4184 30.0957 42.5483 30.0957C43.3958 30.0957 44.2432 30.3782 45.0907 31.2256C45.6556 31.7906 46.2206 32.6381 46.2206 33.768C46.2206 34.898 45.6556 35.7454 45.0907 36.3104Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M44.7854 25.5002V12.5822C44.7854 11.4522 43.938 10.3223 42.5256 10.3223H34.8984" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M11.4517 10.6055H3.54211C2.41217 10.6055 1.28223 11.4529 1.28223 12.8654V41.9614C1.28223 42.5264 1.56471 42.8089 2.12968 42.8089H40.5" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M16.2527 4.95508H12.8629C12.0154 4.95508 11.168 5.52005 11.168 6.64999V42.8082" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M29.8115 4.95508H33.2014C34.0488 4.95508 34.8963 5.52005 34.8963 6.64999V28.1189" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M19.3613 4.95508H26.9884" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M23.3164 1V8.9096" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M17.1016 42.5251V31.2256C17.1016 30.6607 17.6665 30.0957 18.2315 30.0957H27.836C28.401 30.0957 28.966 30.6607 28.966 31.2256V42.5251" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M17.1016 25.8594H28.966" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M39.418 14.5586V18.5134" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M6.36719 14.5586V18.5134" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M6.36719 24.1641V28.4014" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M6.36719 34.0508V38.2881" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>

                    `,
          desc: "协助医院实现“医防融合”的慢病防治局面，依托管理平台实现慢病精细化管理。",
        },
        {
          title: "社区慢病管理",
          icon: `<svg width="100%" height="100%" viewBox="0 0 59 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.5924 46.6562H1" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M10.6309 28.1074H19.9047" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M15.2676 23.8281V32.7453" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M15.2676 17.4072V1.71289H39.5224V26.681" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M39.5254 9.20312H45.9458V23.8273" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M19.5488 7.77734H35.2431" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M19.5488 12.4141H35.2431" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M19.5488 17.0508H35.2431" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M14.9123 14.1973H5.99512V46.6559" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M15.2676 37.7383V46.6555" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.5019 44.1598C53.7885 44.1598 53.4318 43.8031 52.7184 43.8031C51.6484 43.8031 50.935 44.1598 49.8649 44.5165C49.1515 44.8732 48.0815 45.2299 47.3681 45.9433C46.6547 46.6566 45.5847 48.7968 45.5847 48.7968H42.0178C42.0178 48.7968 40.9477 46.2999 40.2343 45.9433C39.521 45.2299 38.8076 44.8732 37.7375 44.5165C37.0241 44.1598 35.9541 43.8031 35.2407 43.8031C34.5273 43.8031 34.1706 43.8031 33.4573 44.1598L31.6738 41.3063C32.0305 40.9496 32.3872 40.5929 32.7439 39.8796C33.4573 38.8095 33.814 37.3827 33.814 36.3127C33.814 34.8859 33.4573 33.8159 32.7439 32.7458C32.3872 32.0324 32.0305 31.3191 31.6738 30.9624L33.4573 28.1089C34.1706 28.1089 34.5273 28.4656 35.2407 28.4656C36.3108 28.4656 37.0241 28.1089 38.0942 27.7522C38.8076 27.3955 39.521 26.6821 40.2343 25.9687C40.9477 25.2554 42.0178 23.1152 42.0178 23.1152H45.5847C45.5847 23.1152 46.6547 25.6121 47.3681 25.9687C48.0815 26.6821 48.7948 27.0388 49.8649 27.3955C50.935 27.7522 51.6484 28.1089 52.7184 28.1089C53.4318 28.1089 53.7885 28.1089 54.5019 27.7522L56.2853 30.6057C55.9286 30.9624 55.5719 31.3191 55.2152 32.0324C54.5019 33.1025 54.1452 34.5292 54.1452 35.5993C54.1452 37.0261 54.5019 38.0961 55.2152 39.1662C55.5719 39.5229 55.9286 39.8796 56.2853 40.5929L54.5019 44.1598Z" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M47.0102 39.1648C46.2968 39.8781 45.2268 40.2348 43.8 40.2348C42.73 40.2348 41.6599 39.8781 40.5898 38.8081C39.8765 38.0947 39.1631 37.0246 39.1631 35.5979C39.1631 34.5278 39.5198 33.4578 40.5898 32.3877C41.3032 31.6743 42.3733 30.9609 43.8 30.9609C44.8701 30.9609 45.9402 31.3176 47.0102 32.3877C47.7236 33.1011 48.437 34.1711 48.437 35.5979C48.437 37.3813 47.7236 38.4514 47.0102 39.1648Z" stroke="black" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>

                    `,
          desc: "上下联动，对接优质医疗资源，赋能基层慢病管理。",
        },
        {
          title: "家庭医生管理",
          icon: `
                    <svg width="100%" height="100%" viewBox="0 0 58 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48.0928 43.3184C47.4564 43.9547 46.5019 44.5911 45.2291 44.5911C44.2746 44.5911 43.32 44.2729 42.3655 43.3184C41.7291 42.682 41.0928 41.7274 41.0928 40.4547C41.0928 39.5002 41.411 38.5456 42.3655 37.5911C43.0019 36.9547 43.9564 36.3184 45.2291 36.3184C46.1837 36.3184 47.1382 36.6365 48.0928 37.5911C48.7291 38.2274 49.3655 39.182 49.3655 40.4547C49.3655 41.7274 48.7291 42.682 48.0928 43.3184Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7736 47.7731C54.1373 47.7731 53.8191 47.4549 53.1827 47.4549C52.2282 47.4549 51.5918 47.7731 50.6373 48.0913C50.0009 48.4094 49.0463 48.7276 48.41 49.364C47.7736 50.0004 46.8191 51.9094 46.8191 51.9094H43.6373C43.6373 51.9094 42.6827 49.6822 42.0463 49.364C41.41 48.7276 40.7736 48.4094 39.8191 48.0913C38.8645 47.7731 38.2282 47.4549 37.2736 47.4549C36.6373 47.4549 36.3191 47.4549 35.6827 47.7731L34.0918 45.2276C34.41 44.9094 34.7282 44.5913 35.0463 43.9549C35.6827 43.0004 36.0009 41.7276 36.0009 40.7731C36.0009 39.5004 35.6827 38.5458 35.0463 37.5913C34.7282 37.2731 34.41 36.9549 34.0918 36.3185L35.6827 33.7731C36.3191 33.7731 36.6373 34.0913 37.2736 34.0913C38.2282 34.0913 38.8645 33.7731 39.8191 33.4549C40.4554 33.1367 41.41 32.8185 42.0463 32.1822C42.6827 31.5458 43.6373 29.6367 43.6373 29.6367H46.8191C46.8191 29.6367 47.7736 31.864 48.41 32.1822C49.0463 32.8185 49.6827 33.1367 50.6373 33.4549C51.5918 33.7731 52.2282 34.0913 53.1827 34.0913C53.8191 34.0913 54.1373 34.0913 54.7736 33.7731L56.3645 36.3185C56.0463 36.6367 55.7282 36.9549 55.41 37.5913C54.7736 38.5458 54.4554 39.8185 54.4554 40.7731C54.4554 42.0458 54.7736 43.0004 55.41 43.9549C55.7282 44.2731 56.0463 44.5913 56.3645 45.2276L54.7736 47.7731Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M24.545 32.1822C17.8632 32.1822 12.4541 26.7731 12.4541 20.0913V5.77308C12.4541 3.54581 14.3632 1.63672 16.5905 1.63672H32.4996C34.7268 1.63672 36.6359 3.54581 36.6359 5.77308V20.0913C36.9541 26.7731 31.545 32.1822 24.545 32.1822Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M12.4541 15.9551H36.9541" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M21.0459 8.63672H29.0004" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M24.8633 4.81836V12.7729" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.4072 32.5009C40.7709 32.1827 40.1345 32.1827 39.18 31.8645C34.7254 30.91 30.9072 30.5918 30.9072 30.5918" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M18.4992 30.5918C18.4992 30.5918 14.3628 31.2282 10.2265 31.8645C6.09011 32.5009 3.54466 35.0463 2.59011 36.6372C1.63556 38.2282 1.31738 40.4554 1.31738 43.6372C1.31738 46.8191 1.31738 46.8191 1.31738 46.8191C1.31738 48.7282 2.90829 50.0009 4.4992 50.0009H23.9083H24.8628H42.681" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M17.2275 30.9102L19.773 41.092C21.0457 46.1829 28.3639 46.1829 29.6366 41.092L32.1821 30.9102" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          desc: "助理家庭医生，提升管理效率，推动科技人文关怀向基层渗透。",
        },
        {
          title: "患者自我管理",
          icon: `
                    <svg width="100%" height="100%" viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.68659 52.2777L1.3372 41.4826C0.999847 39.7958 2.34924 38.1091 4.03599 37.4344L16.1806 33.3862L20.2288 51.2657L22.5902 38.7838L20.2288 34.3982H23.6023H26.9757L24.9517 38.4464L27.3131 50.9283L31.3613 33.0488L40.8071 36.085" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10" stroke-linejoin="round"/>
                    <path d="M22.9278 1.33789C19.5543 1.33789 15.5061 2.34994 13.482 5.38608C12.1326 7.41018 11.7953 9.77163 12.1326 12.1331C12.1326 12.8078 12.1326 13.4825 12.47 13.8198C11.1206 14.4945 11.1206 15.1692 11.4579 18.8801C12.1326 22.2536 14.1567 21.9162 14.1567 21.9162C15.8435 30.3499 21.9158 31.362 23.6025 31.362C25.2893 31.362 31.3615 30.3499 33.0483 21.9162C33.0483 21.9162 35.0724 22.2536 35.7471 18.8801C36.4218 15.5066 36.0844 14.4945 34.735 14.1572L35.0724 12.4704C35.0724 11.121 35.0724 9.77163 34.735 8.42223C33.723 3.36199 29.0001 1.67524 24.6146 1.67524C23.9399 1.33789 23.6025 1.33789 22.9278 1.33789Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.6396 52.6156C53.9649 52.6156 53.6276 52.2783 52.9529 52.2783C51.9408 52.2783 51.2661 52.6156 50.2541 52.953C49.5794 53.2903 48.5673 53.6277 47.8926 54.3024C47.2179 54.9771 46.2059 57.0012 46.2059 57.0012H42.8324C42.8324 57.0012 41.8203 54.6397 41.1456 54.3024C40.4709 53.6277 39.7963 53.2903 38.7842 52.953C37.7722 52.6156 37.0975 52.2783 36.0854 52.2783C35.4107 52.2783 35.0734 52.2783 34.3987 52.6156L32.7119 49.9168C33.0493 49.5795 33.3866 49.2421 33.724 48.5674C34.3987 47.5554 34.736 46.206 34.736 45.1939C34.736 43.8445 34.3987 42.8325 33.724 41.8205C33.3866 41.4831 33.0493 41.1458 32.7119 40.4711L34.3987 37.7723C35.0734 37.7723 35.4107 38.1096 36.0854 38.1096C37.0975 38.1096 37.7722 37.7723 38.7842 37.4349C39.4589 37.0976 40.4709 36.7602 41.1456 36.0855C41.8203 35.4108 42.8324 33.3867 42.8324 33.3867H46.2059C46.2059 33.3867 47.2179 35.7482 47.8926 36.0855C48.5673 36.7602 49.242 37.0976 50.2541 37.4349C51.2661 37.7723 51.9408 38.1096 52.9529 38.1096C53.6276 38.1096 53.9649 38.1096 54.6396 37.7723L56.3264 40.4711C55.989 40.8084 55.6517 41.1458 55.3143 41.8205C54.6396 42.8325 54.3023 44.1819 54.3023 45.1939C54.3023 46.5433 54.6396 47.5554 55.3143 48.5674C55.6517 48.9048 55.989 49.2421 56.3264 49.9168L54.6396 52.6156Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M47.5565 48.2283C46.8818 48.903 45.8697 49.5777 44.5203 49.5777C43.5083 49.5777 42.4962 49.2404 41.4842 48.2283C40.8095 47.5536 40.1348 46.5416 40.1348 45.1922C40.1348 44.1801 40.4721 43.1681 41.4842 42.156C42.1589 41.4813 43.1709 40.8066 44.5203 40.8066C45.5324 40.8066 46.5444 41.144 47.5565 42.156C48.2312 42.8307 48.9058 43.8428 48.9058 45.1922C48.9058 46.2042 48.2312 47.2163 47.5565 48.2283Z" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M12.8076 14.1574C12.8076 14.1574 23.9401 15.8441 27.9883 8.75977C27.9883 8.75977 30.0124 14.4947 35.41 14.1574" stroke="#21252E" stroke-width="1.6" stroke-miterlimit="10" stroke-linejoin="round"/>
                    </svg>
                    `,
          desc: "根据健康评估为患者提供健康指导，为居家慢病管理提供便利。",
        },
      ],
    };
  },
  mounted() {
    var mySwiper = new Swiper(".wp_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".wp_swiper-pagination",
      },
    });
  },
};
</script>

<style scoped>
.box {
  height: 8rem;
}
.mobile_ {
  height: 0 !important;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 20px;
  box-sizing: border-box;
}
.mobile_ .swiper-pagination {
  display: none;
  bottom: 0;
}
.mobile_item_nav {
  background: #fff;
  height: 280px;
  padding: 0 20px;
  width: 90%;
  /* width: 200px; */
  box-sizing: border-box;

  height: auto;
  min-height: 220px;
}
.mobile_item_ {
  justify-content: center;
  display: flex;
  width: calc(50vw - 20px);
  box-sizing: border-box;
}
.swiper-slide {
  padding: 0 20px;
  box-sizing: border-box;
}
.item_icon {
  width: 0.58rem;
  height: 0.58rem;
}
.item_title {
  font-weight: 900;
  margin-top: 0.27rem;
  font-size: 0.22rem;
  line-height: 0.31rem;
}
.item_desc {
  color: #999;
  margin-top: 0.14rem;
  font-size: 0.14rem;
  line-height: 0.24rem;
}
.item_box {
  cursor: pointer;
  width: 2.76rem;
  height: 3.2rem;
  transition: all 0.5s;
  background: #fff;
  color: #000;
  margin: 0.88rem 0.2rem;
  padding: 0.51rem 0.46rem;
  box-sizing: border-box;
}
.item_box:hover {
  transform: translateY(-10px);
}
.ctx {
  z-index: 2;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg {
  background: #000;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}
.title {
  letter-spacing: 2px;
  margin: 16px 0;
  text-align: center;
  font-weight: normal;
  margin-top: 1.5rem;

  font-size: 0.38rem;
  line-height: 0.53rem;
  margin-bottom: 0.25rem;
}
.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .mobile_ {
    display: block;
    height: auto;
    overflow: visible;
    padding: 20px 0;
  }
  .mobile_ .swiper-pagination {
    display: inline-block;
  }
  .pc_ {
    display: none;
  }
  .mobile_item_nav {
    margin-top: 20px;
  }
  .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    margin-top: 0.98rem;
    width: 4.77rem;
  }
  .mobile_item_nav {
    width: 3.15rem;
    height: 3.73rem;
    margin: 0 0;
  }
  .mobile_ .tips {
    margin-bottom: 1rem;
  }
  .swiper-slide {
    padding: 0 0;
  }
  .mobile_item_ {
    width: 100%;
    margin-left: 0.3rem;
  }
  .box {
    height: 10rem;
  }
  .wp_swiper {
    height: 5rem !important;
  }
}
</style>