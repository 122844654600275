<template>
  <div class="">
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          show_desc: true,
          title: '一体化全程健康管理平台',
          desc: '以一体化为理念、构建健康体检、智慧慢病管理、智慧随访、全程满意度调查、健康宣教、智能设备等一体化的解决方案和产品矩阵',
          show_desc: true,
        }"
        :img="require('@/assets/WholeProcessHealth/banner.png')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <is01com v-runshow />
      <is02com v-runshow />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import is01com from "@/components/WholeProcessHealth/is_01_com.vue";
import is02com from "@/components/WholeProcessHealth/is_02_com.vue";
// @ is an alias to /src
export default {
  name: "WholeProcessHealth",
  components: {
    commonTop,
    isHeader,
    isFooter,
    is01com,
    is02com,
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
  methods: {},
};
</script>
