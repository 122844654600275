<template>
  <div class="box">
    <div class="flex flex_d j_c_c a_i_c" style="color: #444">
      <h2 class="title">我们的生态价值链</h2>
      <p class="sub" style="">
        院内院外、线上线下一体化，全程闭环、精细化、智慧化、个性化的健康管理服务
      </p>
      <div class="tips"></div>
    </div>

    <div
      class="pc_ flex j_c_c a_i_c p_r"
      style="margin-top: 0.4rem; padding: 30px 0"
    >
      <svg
        width="36vw"
        height="36vw"
        viewBox="0 0 478 478"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M239 478C370.996 478 478 370.996 478 239C478 107.004 370.996 0 239 0C107.004 0 0 107.004 0 239C0 370.996 107.004 478 239 478ZM239 458.88C360.436 458.88 458.88 360.436 458.88 239C458.88 117.564 360.436 19.12 239 19.12C117.564 19.12 19.12 117.564 19.12 239C19.12 360.436 117.564 458.88 239 458.88Z"
          fill="#E8F6EE"
        />
      </svg>

      <div class="ctx_center">
        <div>全程健康</div>
        <div>生态社区</div>
      </div>

      <div
        class="flex a_i_c p_a is_part"
        :class="['is_part_' + (index + 1)]"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="item_box">
          <div class="item_title">
            {{ item.title }}
          </div>
          <div class="ctx_box">
            <div v-for="(item_, index_) in item.list" :key="index_">
              <div class="item_row">{{ index_ + 1 }} {{ item_ }}</div>
            </div>
          </div>
        </div>
        <div class="item_icon">
          <div v-html="item.icon" class="item_icon_pro"></div>
        </div>
      </div>
    </div>

    <div class="mobile_">
      <img
        style="width: 100%"
        :src="require('@/assets/WholeProcessHealth/group.png')"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "慢病患者",
          icon: `<svg width="100%" height="100%" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.85209 57.2967L1.37061 45.4448C1.00024 43.593 2.48172 41.7411 4.33357 41.0004L17.6669 36.5559L22.1114 56.1855L24.7039 42.4818L22.1114 37.667H25.8151H29.5188L27.2965 42.1115L29.8891 55.8152L34.3336 36.1855L47.6669 40.63C49.5188 41.3707 50.6299 43.2226 50.6299 45.0744L49.1484 57.2967" stroke="white" stroke-width="1.6" stroke-miterlimit="10" stroke-linejoin="round"/>
                    <path d="M25.0737 1.37109C21.37 1.37109 16.9256 2.4822 14.7034 5.81554C13.2219 8.03776 12.8515 10.6304 13.2219 13.2229C13.2219 13.9637 13.2219 14.7044 13.5923 15.0748C12.1108 15.8155 12.1108 16.5563 12.4812 20.6304C13.2219 24.3341 15.4441 23.9637 15.4441 23.9637C17.296 33.2229 23.9626 34.3341 25.8145 34.3341C27.6663 34.3341 34.333 33.2229 36.1849 23.9637C36.1849 23.9637 38.4071 24.3341 39.1478 20.6304C39.8886 16.9267 39.5182 15.8155 38.0367 15.4452L38.4071 13.5933C38.4071 12.1118 38.4071 10.6304 38.0367 9.14887C36.9256 3.59332 32 1.50073 27 1.37109C26 1.37113 25.8145 1.37109 25.0737 1.37109Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M13.9629 15.4455C13.9629 15.4455 26.1851 17.2973 30.6296 9.51953C30.6296 9.51953 32.8518 15.8158 38.7777 15.4455" stroke="white" stroke-width="1.6" stroke-miterlimit="10" stroke-linejoin="round"/>
                    </svg>
                    `,
          list: [
            "日常有监护，个人与医疗有衔接，节约大量时间",
            "提升健康管理水平，病情得到有效控制",
            "科技为健康带来人文关怀",
            "获得药品福利，降低治疗开支。",
          ],
        },
        {
          title: "家医/社区医生/健康管理师",
          icon: `<svg width="100%" height="100%" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1379_32095)">
                    <path d="M25.8318 33.1363C18.7848 33.1363 13.0801 27.3881 13.0801 20.2873V5.07147C13.0801 2.70456 15.0935 0.675781 17.4425 0.675781H34.221C36.57 0.675781 38.5834 2.70456 38.5834 5.07147V20.2873C38.919 27.3881 33.2143 33.1363 25.8318 33.1363Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M13.0801 15.8926H38.919" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M22.1406 8.11523H30.5299" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M26.168 4.05859V12.5118" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M19.4567 31.4453C19.4567 31.4453 15.0943 32.1216 10.7319 32.7978C6.3695 33.4741 3.68493 36.1791 2.67822 37.8698C1.67151 39.5604 1.33594 41.9273 1.33594 45.3086C1.33594 48.6899 1.33594 48.6899 1.33594 48.6899C1.33594 50.7187 3.01379 52.0713 4.69164 52.0713H25.1614H26.1682H46.6379C48.6514 52.0713 49.9937 50.3806 49.9937 48.6899C49.9937 48.6899 49.9937 48.3518 49.9937 45.3086C49.9937 42.2655 49.6581 39.5604 48.6514 37.8698C47.6447 36.1791 45.2957 33.4741 40.5977 32.7978C35.8997 32.1216 32.2084 31.4453 32.2084 31.4453" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M18.1143 31.7852L20.7988 42.6053C22.1411 48.0154 29.8592 48.0154 31.2015 42.6053L33.8861 31.7852" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1379_32095">
                    <rect width="51" height="53" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
          list: [
            "大大减轻医生在慢病管理体系付出的劳动力",
            "可以快速获得慢病医疗数据",
            "增加院外服务管理，获得服务型报酬",
          ],
        },
        {
          title: "卫健委医院",
          icon: `<svg width="100%" height="100%" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1379_32078)">
                        <path d="M12.25 10.625H3.5C2.25 10.625 1 11.5625 1 13.125V45.3125C1 45.9375 1.3125 46.25 1.9375 46.25H48.1875C48.8125 46.25 49.125 45.9375 49.125 45.3125V12.8125C49.125 11.5625 48.1875 10.3125 46.625 10.3125H38.1875" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M17.5625 4.375H13.8125C12.875 4.375 11.9375 5 11.9375 6.25V46.25" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M32.5625 4.375H36.3125C37.25 4.375 38.1875 5 38.1875 6.25V46.25" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M21 4.375H29.4375" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M25.0625 0V8.75" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M18.5 45.9375V33.4375C18.5 32.8125 19.125 32.1875 19.75 32.1875H30.375C31 32.1875 31.625 32.8125 31.625 33.4375V45.9375" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M18.5 27.5H31.625" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M43.1875 15V19.375" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M43.1875 22.1875V26.5625" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M43.1875 29.375V34.0625" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M43.1875 36.5625V41.25" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M6.3125 15V19.375" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M6.3125 22.1875V26.5625" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M6.3125 29.375V34.0625" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M6.3125 36.5625V41.25" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1379_32078">
                        <rect width="50" height="46.875" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    `,
          list: [
            "政府主导当地区域慢病管理体系",
            "有效管理慢病人群，降低并发症几率",
            "提升公卫整体服务效能，提升患者满意度",
            "满足医院各项考核、精细化发展和评级要求",
          ],
        },
        {
          title: "药店",
          icon: `<svg width="100%" height="100%" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7832 18.482H1.34961V5.54492H17.7832" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M34.2168 5.54492H50.6504V18.482H34.2168" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M1.34961 51H50.6503" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M5.89551 18.4824V50.9999" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.4541 18.4824V50.9999" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M17.7832 51.0001V35.6155C17.7832 34.5665 18.8322 33.5176 19.8811 33.5176H32.1189C33.1678 33.5176 34.2168 34.5665 34.2168 35.6155V50.6504" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M26.1751 22.3286C31.9683 22.3286 36.6646 17.6323 36.6646 11.8391C36.6646 6.04592 31.9683 1.34961 26.1751 1.34961C20.3819 1.34961 15.6855 6.04592 15.6855 11.8391C15.6855 17.6323 20.3819 22.3286 26.1751 22.3286Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M21.2793 11.8398H30.7199" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M25.8252 7.29297V16.7335" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          list: [
            "处方外流，药物采购流量导入",
            "增加药事服务，门店人员获得服务性收入",
            "增加患者服务高粘性",
          ],
        },
        {
          title: "药企",
          icon: `<svg width="100%" height="100%" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 51.7695H51" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M4.8457 51.7698V22.5391" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M4.8457 8.30784V1.38477H30.2303V51.7694" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M35.6152 51.7686V19.4609H47.1537V51.7686" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M12.1543 15.2305H22.5389" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M12.1543 27.5391H22.5389" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M12.1543 39.8457H22.5389" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M1.38281 15.2305H8.6905" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M4.8457 11.7695V19.0772" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          list: [
            "在获得医院授权后，利用患者“可用不可见”数据，协助药物研发和服务跟踪",
            "贴近基层患者，增强患者粘性",
          ],
        },
        {
          title: "政府医保",
          icon: `<svg width="100%" height="100%" viewBox="0 0 52 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M46.5094 36.6282H5.49142C3.09621 36.6282 1.2998 34.8318 1.2998 32.4366V5.49044C1.2998 3.09524 3.09621 1.29883 5.49142 1.29883H46.5094C48.9046 1.29883 50.701 3.09524 50.701 5.49044V32.4366C50.701 34.8318 48.9046 36.6282 46.5094 36.6282Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M1.2998 13.5742H50.701" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M1.2998 21.957H25.8507" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M32.4375 26.1504H43.2159" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.8262 20.7598V31.5382" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          list: ["直接降低医保赔付成本", "动态监测慢性病药物用量和效率"],
        },
      ],
    };
  },
};
</script>

<style scoped>
.sub {
  font-size: 0.38rem;
  line-height: 0.53rem;
  margin-bottom: 0.25rem;
}
.box {
}
.pc_ {
  transform: scale(0.85);
}
.mobile_ {
  display: none;
  padding: 30px;
}

.ctx_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #1aa858;
  font-size: 34px;
  line-height: 180%;
  font-weight: 800;
}
.is_part_1 {
  top: 2vw;
  right: calc(50% + 9vw);
}
.is_part_2 {
  top: 16vw;
  right: calc(50% + 15vw);
}
.is_part_3 {
  top: 28vw;
  right: calc(50% + 9vw);
}
.is_part_4 {
  top: 2vw;
  left: calc(50% + 9vw);
  flex-direction: row-reverse;
}
.is_part_5 {
  top: 16vw;
  left: calc(50% + 15vw);
  flex-direction: row-reverse;
}
.is_part_6 {
  top: 28vw;
  left: calc(50% + 11vw);
  flex-direction: row-reverse;
}
.is_part_5 .item_icon {
  margin-right: 30px;
  margin-left: 0;
}
.is_part_6 .item_icon {
  margin-right: 30px;
  margin-left: 0;
}
.is_part_4 .item_icon {
  margin-right: 30px;
  margin-left: 0;
}
.item_icon {
  background: #a9a9aa;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: 30px;
  flex: none;
  position: relative;
}
.item_icon_pro {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.item_box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.is_part:hover .item_box {
  border: 1px solid #1aa858;
}
.is_part:hover .item_title {
  color: #1aa858;
}
.is_part:hover .item_icon {
  background: #1aa858;
}
.item_title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 4px;
}
.item_row {
  font-size: 12px;
  color: #999;
  line-height: 140%;
}
/* h2{
    font-size: 18px;
    font-weight: 700;
    margin: 20px;
    text-align: center;
    margin-top: 60px;
} */
.title {
  letter-spacing: 2px;
  margin: 16px 0;
  color: #61646b;
  text-align: center;
  margin-top: 1.2rem;
  font-weight: normal;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .mobile_ {
    display: block;
  }
  .pc_ {
    display: none;
  }
  .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    margin-top: 0.85rem;
    margin-bottom: 0 !important;
    font-weight: 800;
  }
  .sub {
    font-size: 0.38rem;
    line-height: 0.67rem;
    text-align: center;
    margin-top: 0.16rem !important;
    width: 6.76rem;
  }
}

@media screen and (min-width: 1910px) {
  .title {
    font-weight: 900;
    font-size: 30px;
    line-height: 100%;
  }

  .pc_desc {
    font-weight: 400 !important;
    font-size: 38px !important;
    line-height: 53px !important;
  }
}
</style>
